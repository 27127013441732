import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../services/global.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService { 
  constructor(
    private http: HttpClient,
    private gs: GlobalService) {
      
    }
    // Home page
    propertyAdd(data) {
      return new Promise((resolve, reject) => {
        this.http.post(this.gs.apiURl + 'api/add-property', data).subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
      });
    }
  
    inquiryReqAdd(data) {
      return new Promise((resolve, reject) => {
        this.http.post(this.gs.apiURl + 'api/add-inquiry', data).subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
      });
  }
  
  // Admin Panel Section 

  filterProperty(data) {
    return new Promise((resolve, reject) => {
      this.http.post(this.gs.apiURl + 'api/search-pro', data).subscribe(res => {
        resolve(res);
      }, (err) => {
        reject(err);
      });
    });
  }
  
  // Get Property
  getAllPropery() {
    return new Promise((resolve, reject) => {
      this.http.get(this.gs.apiURl + 'api/get-property').subscribe(result => {
        resolve(result);
      }, (err) => {
        reject(err);
      });
    });
  }

  permissionProperty(data) {
    return new Promise((resolve, reject) => {
      this.http.post(this.gs.apiURl + 'api/permission-pro', data).subscribe(result => {
        resolve(result);
      }, (err) => {
        reject(err);
      });
    });
  }

  editproperty(id) {
    return new Promise((resolve, reject) => {
      this.http.post(this.gs.apiURl + 'api/edit-property', id).subscribe(result => {
        resolve(result);
      }, (err) => {
        reject(err);
      });
    });
  }

  // delete Property
  deleteproperty(id) {
    return new Promise((resolve, reject) => {
      this.http.post(this.gs.apiURl + 'api/remove-property', id).subscribe(result => {
        resolve(result);
      }, (err) => {
        reject(err);
      });
    });
  }


  //get inquiry 
  getAllInquity() {
    return new Promise((resolve, reject) => {
      this.http.get(this.gs.apiURl + 'api/get-inquery').subscribe(result => {
        resolve(result);
      }, (err) => {
        reject(err);
      });
    });
  }

  // delete Inquiry
  deleteInquiry(id) {
    return new Promise((resolve, reject) => {
      this.http.post(this.gs.apiURl + 'api/remove-inquiry', id).subscribe(result => {
        resolve(result);
      }, (err) => {
        reject(err);
      });
    });
  }

  // For rent Property Section
  forRentProperty(data) {
    return new Promise((resolve, reject) => {
      this.http.post(this.gs.apiURl + 'api/web/get-property', data).subscribe(result => {
        resolve(result);
      }, (err) => {
        reject(err);
      });
    });
  }

  // Get Property Type

  getType() {
    return new Promise((resolve, reject) => {
      this.http.get(this.gs.apiURl + 'api/web/pro-type').subscribe(result => {
        resolve(result);
      }, (err) => {
        reject(err);
      });
    });
  }


}
