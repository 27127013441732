import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, ValidationErrors } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  apiURl: any = "https://www.khodiyarestate.com/";
  // apiURl: any = "http://127.0.0.1:8000/";
  constructor(
    private toastr: ToastrService) { }

  validationFireOnSubmit(fg: FormGroup) {
    this.toastr.error('Fill all the required details !');
    
    Object.keys(fg.controls).forEach(key => {
      const control = fg.get(key);
      const controlErrors: ValidationErrors = fg.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {
          control.markAsDirty({ onlySelf: true });
          control.markAsTouched({ onlySelf: true });
        });
      }
    });
  }

  aminitiesArr = [{
    "title": 'Club House',
    checked: true,
  }, {
    "title": 'Piped Gas',
    checked: false,
  }, {
    "title": 'Park',
    checked: false,
  }, {
    "title": 'Lift',
    checked: false,
  }, {
    "title": 'Swimming Pool',
    checked: false,
  }, {
    "title": 'Conference Room',
    checked: false,
  }, {
    "title": 'Security',
    checked: false,
  }, {
    "title": 'Air Conditioned',
    checked: false,
  }];
}
